import React, { createContext } from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import 'assets/stylesheets/global.scss';
import reportWebVitals from './reportWebVitals';
import Routes from './routes';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import RootStore from './mobXstore';
import { toJS } from 'mobx';

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN_KEY,
  integrations: [new Integrations.BrowserTracing()],
  environment: process.env.REACT_APP_NODE_ENV,
  tracesSampleRate: 0.0,
  ignoreErrors: ['ChunkLoadError'],
});

export const StoreContext = createContext();

const StoreProvider = ({ children }) => {
  console.log('Environment', process.env.REACT_APP_NODE_ENV);
  const rootStore = new RootStore();
  return (
    <StoreContext.Provider
      value={{
        rootStore: rootStore,
        userStore: rootStore.UserStore,
        restaurantManagementStore: rootStore.RestaurantManagementStore,
        accountManagementStore: rootStore.AccountManagementStore,
        userManagementStore: rootStore.UserManagementStore,
        mealManagementStore: rootStore.MealManagementStore,
        staffManagementStore: rootStore.StaffManagementStore,
        placeOrderStore: rootStore.PlaceOrderStore,
        marketManagementStore: rootStore.MarketManagementStore,
        deliveryManagementStore: rootStore.DeliveryManagementStore,
      }}>
      {children}
    </StoreContext.Provider>
  );
};

const render = AppRoutes =>
  ReactDOM.render(
    <React.StrictMode>
      <StoreProvider>
        <AppRoutes />
      </StoreProvider>
    </React.StrictMode>,
    document.getElementById('root')
  );

render(Routes);

if (module.hot) {
  module.hot.accept('./routes', () => {
    const NextApp = require('./routes').default;
    render(NextApp);
  });
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
