import { makeObservable, observable, action } from 'mobx';
import { makePersistable, clearPersistedStore } from 'mobx-persist-store';
import { client } from '../store';
import api from '../store/api';
import qs from 'qs';
import array from 'utils/helpers/array';
import LocalStorage from 'utils/helpers/localStorage';
import { alertHandler } from 'utils/middlewares/alertHandler';
import { toJS } from 'mobx';
import axios from 'axios';
import dateTime from 'utils/helpers/dateTime';

class RestaurantManagementStore {
  restaurants = {};
  restaurantDetail = null;
  markets = [];
  zones = [];
  bankingInformation = null;
  accountingEmails = null;
  schedules = [];
  allSchedules = [];

  cateringOrders = [];
  eta = null;
  shipmentsETA = [];

  menus = [];
  menu = null;
  optionCategories = null;
  menuTypes = [];
  defaultMenuType = null;
  menuCategories = [];
  tags = [];
  employees = {};
  reportHistory = [];

  //orders
  orderSummaries = {};
  cateringSummaries = {};
  hungerhubCateringSummaries = {};
  directCateringSummaries = {};
  ordersPrepare = [];

  ordersPrepareTotalBags = 0;
  ordersPrepareTotalItems = 0;
  defaultDateSelect = new Date();

  destinationCodes = [];
  defaultDestinationCode = {};

  //menu editing page
  menusList = [];
  categoriesList = [];
  itemsList = [];
  optionGroupList = [];
  menuOptionsList = [];

  specificMenuCategories = [];
  specificMenu = null; //to hold the data for filtered menu
  specificCategory = null;
  specificItem = null;
  specificOptionGroup = null;
  specificOption = null;
  forceUpdateOptionCategories = false;

  itemBadges = [];
  entireMenus = [];
  fortnightlyStatus = null;

  fortnightlyStatus = false;

  constructor() {
    makeObservable(this, {
      restaurants: observable,
      schedules: observable,
      allSchedules: observable,
      cateringOrders: observable,
      restaurantDetail: observable,
      markets: observable,
      zones: observable,
      searchRestaurants: action,
      menus: observable,
      menu: observable,
      optionCategories: observable,
      menuTypes: observable,
      defaultMenuType: observable,
      menuCategories: observable,
      employees: observable,
      reportHistory: observable,

      defaultDateSelect: observable,
      ordersPrepareTotalBags: observable,
      ordersPrepareTotalItems: observable,

      destinationCodes: observable,
      defaultDestinationCode: observable,
      orderSummaries: observable,
      cateringSummaries: observable,
      hungerhubCateringSummaries: observable,
      directCateringSummaries: observable,

      ordersPrepare: observable,
      eta: observable,
      shipmentsETA: observable,

      menusList: observable,
      categoriesList: observable,
      itemsList: observable,
      optionGroupList: observable,
      menuOptionsList: observable,
      specificMenu: observable,
      specificMenuCategories: observable,
      specificCategory: observable,
      specificItem: observable,
      specificOptionGroup: observable,
      specificOption: observable,
      itemBadges: observable,
      forceUpdateOptionCategories: observable,
      fortnightlyStatus: observable,
      entireMenus: observable,
      fortnightlyStatus: observable,
    });
  }

  async getMarkets(params) {
    let paramsString = qs.stringify(params);
    return client()
      .get(`${api.markets()}?${paramsString}`)
      .then(
        action('fetchSuccess', data => {
          this.markets = data?.data;
          return data;
        }),
        action('fetchError', error => {
          alertHandler(error.response);
          return error;
        })
      );
  }

  async getZones(params) {
    let paramsString = qs.stringify(params);
    return client()
      .get(`${api.zones()}?${paramsString}`)
      .then(
        action('fetchSuccess', data => {
          this.zones = data?.data;
        }),
        action('fetchError', error => {
          alertHandler(error.response);
          return error;
        })
      );
  }
  async searchRestaurants(params) {
    this.restaurants = {};
    let paramsString = qs.stringify(params);
    return client()
      .get(`${api.restaurants()}?${paramsString}`)
      .then(
        action('fetchSuccess', data => {
          this.restaurants = data?.data;
          return data?.data;
        }),
        action('fetchError', error => {
          alertHandler(error.response);
          return error;
        })
      );
  }

  async getRestaurantDetail(restaurantId, params) {
    let paramsString = qs.stringify(params);
    return client()
      .get(`${api.restaurants()}/${restaurantId}?${paramsString}`)
      .then(
        action('fetchSuccess', data => {
          this.restaurantDetail = data?.data;
          return data?.data;
        }),
        action('fetchError', error => {
          // alertHandler(error.response);
          return error;
        })
      );
  }

  async createRestaurant(payload, params) {
    let paramsString = qs.stringify(params);
    return client()
      .post(`${api.restaurants()}?${paramsString}`, payload)
      .then(
        action('fetchSuccess', data => {
          return data?.data?.data;
        }),
        action('fetchError', error => {
          alertHandler(error.response);
          return error;
        })
      );
  }

  async getAccountingEmails(restaurantId, params) {
    let paramsString = qs.stringify(params);

    return client()
      .get(`${api.accountingEmails(restaurantId)}?${paramsString}`)
      .then(
        action('fetchSuccess', data => {
          this.accountingEmails = data?.data?.data;
          return data;
        }),
        action('fetchError', error => {
          alertHandler({
            title: 'No Information Found',
            body: 'No user associated with this account.',
          });
          return error;
        })
      );
  }

  async updateAccountingEmails(restaurantId, params, emails) {
    let paramsString = qs.stringify(params);
    return client()
      .put(`${api.accountingEmails(restaurantId)}?${paramsString}`, {
        accounting_emails: emails,
      })
      .then(
        action('fetchSuccess', data => {
          this.accountingEmails = data?.data?.data;
          alertHandler({
            title: 'Accounting emails updated',
            body: 'Accounting emails have been updated successfully.',
          });
          return data;
        }),
        action('fetchError', error => {
          alertHandler({
            title: 'No Information Found',
            body: 'No user associated with this account.',
          });
          return error;
        })
      );
  }

  async getBankingInformation(restaurantId, params) {
    let paramsString = qs.stringify(params);

    return client()
      .get(`${api.bankingInformation(restaurantId)}?${paramsString}`)
      .then(
        action('fetchSuccess', data => {
          this.bankingInformation = data?.data?.data;
          return data;
        }),
        action('fetchError', error => {
          alertHandler({
            title: 'No Information Found',
            body: 'No user associated with this account.',
          });
          return error;
        })
      );
  }

  async updateBankingInformation(restaurantId, params, payload) {
    let paramsString = qs.stringify(params);
    return client()
      .put(`${api.bankingInformation(restaurantId)}?${paramsString}`, payload)
      .then(
        action('fetchSuccess', data => {
          this.bankingInformation = data?.data?.data;
          return data;
        }),
        action('fetchError', error => {
          alertHandler({
            title: 'No Information Found',
            body: 'No user associated with this account. If you need assistance please start a live chat or email us at help@hungerhub.com.',
          });
          return error;
        })
      );
  }

  async exportSalesReport(email, restaurantId, employeeId, startDate, endDate) {
    const payload = {
      email,
      restaurant_id: restaurantId,
      employee_id: employeeId,
      start_date: startDate,
      end_date: endDate,
    };

    return client()
      .post(api.salesReport(), payload)
      .then(
        action('fetchSuccess', data => {
          alertHandler({
            title: 'Report generated',
            body: `Usage report generated from ${startDate} to ${endDate} and emailed.`,
          });
          return data;
        }),
        action('fetchError', error => {
          alertHandler({
            title: error?.response?.data?.message || 'Unable to generate sales report',
            body: 'If you need assistance please start a live chat or email us at help@hungerhub.com.',
          });
          return error;
        })
      );
  }

  async getRestaurantProfile(restaurantId, params) {
    let paramsString = qs.stringify(params);
    return client()
      .get(`${api.restaurantProfile(restaurantId)}?${paramsString}`)
      .then(
        action('fetchSuccess', data => {
          return data;
        }),
        action('fetchError', error => {
          alertHandler({
            title: 'No Information Found',
            body: 'No user associated with this account. If you need assistance please start a live chat or email us at help@hungerhub.com.',
          });
          return error;
        })
      );
  }

  async updateRestaurantProfile(restaurantId, payload, params) {
    let paramsString = qs.stringify(params);
    return client()
      .put(`${api.restaurantProfile(restaurantId)}?${paramsString}`, payload)
      .then(
        action('fetchSuccess', data => {
          alertHandler({
            title: 'Restaurant Information Updated.',
            body: '',
          });
          return data?.data?.data;
        }),
        action('fetchError', error => {
          alertHandler(error.response);
          return error;
        })
      );
  }

  async updateRestaurantAvailability(id, params) {
    let paramsString = qs.stringify(params);
    return client()
      .put(`${api.operatingHoursAvailable(id)}?${paramsString}`)
      .then(
        action('fetchSuccess', data => {
          // alertHandler({
          //   title: 'Restaurant Information Updated.',
          //   body: '',
          // });
          return data?.data?.data;
        }),
        action('fetchError', error => {
          alertHandler(error.response);
          return error;
        })
      );
  }

  async getSchedules(params) {
    let paramsString = qs.stringify(params);
    return client()
      .get(`${api.schedules()}?${paramsString}`)
      .then(
        action('fetchSuccess', data => {
          this.schedules = data?.data?.data;
          return data;
        }),
        action('fetchError', error => {
          alertHandler(error.response);
          return error;
        })
      );
  }

  async getAllRestaurantsSchedules(params) {
    let paramsString = qs.stringify(params);
    return client()
      .get(`${api.allSchedules()}?${paramsString}`)
      .then(
        action('fetchSuccess', data => {
          this.allSchedules = data?.data;
          return data;
        }),
        action('fetchError', error => {
          alertHandler(error.response);
          return error;
        })
      );
  }

  async getCateringOrders(params) {
    let paramsString = qs.stringify(params);
    return client()
      .get(`${api.cateringOrders()}?${paramsString}`)
      .then(
        action('fetchSuccess', data => {
          this.cateringOrders = data?.data;
          return data;
        }),
        action('fetchError', error => {
          alertHandler(error.response);
          return error;
        })
      );
  }

  async updateSchedule(restaurantId, shipmentIds, acceptance, employeeId) {
    return client()
      .put(`${api.schedules()}/${restaurantId}?employee_id=${employeeId}`, {
        ids: shipmentIds,
        acceptance,
      })
      .then(
        action('fetchSuccess', data => {
          return data;
        }),
        action('fetchError', error => {
          alertHandler(error.response);
          return error;
        })
      );
  }

  async confirmOrSendReminder(employeeId, payload) {
    return client()
      .put(`${api.allSchedules()}?employee_id=${employeeId}`, payload)
      .then(
        action('fetchSuccess', data => {
          return data;
        }),
        action('fetchError', error => {
          alertHandler(error.response);
          return error;
        })
      );
  }

  async getRestaurantMenus(params, selectionType, menuId) {
    let paramsString = qs.stringify(params);
    return client()
      .get(`${api.menus()}?${paramsString}`)
      .then(
        action('fetchSuccess', data => {
          let _menuTypes = data?.data?.map(function (x) {
            return {
              value: x[0],
              label: x[1],
            };
          });

          this.menuTypes = _menuTypes;

          if (this.defaultMenuType === null) this.defaultMenuType = this.menuTypes[0];
          else if (selectionType === 'update') {
            let selectedMenu = toJS(this.defaultMenuType);
            this.defaultMenuType = _menuTypes.find(obj => {
              return obj.value === selectedMenu.value;
            });
          } else if (menuId)
            this.defaultMenuType = _menuTypes.find(obj => {
              return obj.value === menuId;
            });
          if (array.isPresent(_menuTypes))
            this.getMenuDetails(_menuTypes[0]?.value, { employee_id: params.employee_id });
          return data;
        }),
        action('fetchError', error => {
          alertHandler(error.response);
          return error;
        })
      );
  }

  getMenuDetails(menuId, params) {
    let paramsString = qs.stringify(params);

    return client()
      .get(`${api.menus()}/${menuId}?${paramsString}`)
      .then(
        action('fetchSuccess', data => {
          this.menu = data?.data?.data;
          this.pluckAndSetCategories();
        })
      );
  }

  resetDefaultMenu() {
    this.defaultMenuType = null;
  }

  updateDefaultMenu(selected, params) {
    this.defaultMenuType = selected;
    this.menuCategories = [];
    this.menu = null;
    if (params) return this.getMenuDetails(selected?.value, params);
  }

  pluckAndSetCategories() {
    let menu = toJS(this.menu);
    let _categories = menu?.attributes?.menu_categories.map(({ attributes }) => ({
      id: attributes.id,
      name: attributes.name,
    }));

    this.menuCategories = _categories;
  }

  clearOptionCategories() {
    this.optionCategories = null;
  }

  async getOptionCategories(itemId, params) {
    let paramsString = qs.stringify(params);
    return client()
      .get(`${api.optionCategories(itemId)}?${paramsString}`)
      .then(
        action('fetchSuccess', data => {
          this.optionCategories = data;
          return data;
        }),
        action('fetchError', error => {
          alertHandler(error.response);
          return error;
        })
      );
  }

  async createMenu(selectionType, payload, employeeId, restaurantId, menuId = null) {
    return (
      selectionType === 'update'
        ? client().put(`/staff/restaurant/menus/${menuId}?employee_id=${employeeId}`, payload)
        : client().post(`/staff/restaurant/menus?employee_id=${employeeId}`, payload)
    ).then(
      action('fetchSuccess', data => {
        this.getRestaurantMenus(
          { employee_id: employeeId, restaurant_id: restaurantId, only_name_and_id: true },
          selectionType,
          data?.data?.data?.id
        );
        return data?.data;
      }),
      action('fetchError', error => {
        alertHandler({
          title: 'Unable to save or update',
          body: 'Please try to change the selected images or verify the information.',
        });
        return error;
      })
    );
  }

  async getMenu(menuId, employeeId) {
    let params = { employee_id: employeeId };
    let paramsString = qs.stringify(params);
    return client()
      .get(`${api.menus()}/${menuId}?${paramsString}`)
      .then(
        action('fetchSuccess', data => {
          return data;
        }),
        action('fetchError', error => {
          alertHandler(error.response);
          return error;
        })
      );
  }

  async getTags(params) {
    let paramsString = qs.stringify(params);
    return client()
      .get(`${api.tags()}?${paramsString}`)
      .then(
        action('fetchSuccess', data => {
          let _tags = data?.data?.data;
          this.tags = _tags.map(({ attributes: { name } }) => {
            return { label: name, value: name };
          });
          return data;
        }),
        action('fetchError', error => {
          alertHandler(error.response);
          return error;
        })
      );
  }

  async getShipments(params, timeZone) {
    let paramsString = qs.stringify(params);
    this.orderSummaries = [];
    return client()
      .get(`${api.restaurantShipments()}?${paramsString}`)
      .then(
        action('fetchSuccess', data => {
          this.orderSummaries = data.data;
          let _destinationCodes = [];
          data?.data?.data?.map(summary =>
            _destinationCodes.push({
              label: `${dateTime.formatTime(summary.cutoff, timeZone)} - ${
                summary.destination_code
              }`,
              value: summary,
              shipment_id: summary.shipment_id,
            })
          );
          this.destinationCodes = _destinationCodes;

          return data;
        }),
        action('fetchError', error => {
          alertHandler(error.response);
          return error;
        })
      );
  }

  async getCateringShipments(params) {
    let paramsString = qs.stringify(params);

    return client()
      .get(`${api.restaurantShipments()}?${paramsString}`)
      .then(
        action('fetchSuccess', data => {
          this.cateringSummaries = data.data;

          this.hungerhubCateringSummaries = {
            data: data.data.data.filter(order => order.source_of_business == 'hungerhub'),
          };

          this.directCateringSummaries = {
            data: data.data.data.filter(order => order.source_of_business == 'restaurant'),
          };

          let _destinationCodes = [];
          data?.data?.data?.map(summary =>
            _destinationCodes.push({
              label: `${summary.destination_code} - ${
                summary.source_of_business == 'hungerhub' ? 'hungerhub Catering' : 'Direct Catering'
              }`,
              value: summary,
              shipment_id: summary.shipment_id,
            })
          );
          this.destinationCodes = this.destinationCodes.concat(_destinationCodes);

          return data;
        }),
        action('fetchError', error => {
          alertHandler(error.response);
          return error;
        })
      );
  }

  setDefaultSummaryDate(date) {
    this.defaultDateSelect = date;
  }

  async getOrderDetails(shipmentId, params) {
    let paramsString = qs.stringify(params);
    //setting up axios here again because this specific request required Accept: 'application/json' in the headers. Else it would't work
    const API_URL = process.env.REACT_APP_API_URL_PROD;

    let access_token = LocalStorage.getAccessToken();
    let url = `${API_URL}${api.restaurantShipments()}/${shipmentId}?${paramsString}`;

    axios({
      url,
      method: 'GET',
      headers: {
        'ACCESS-TOKEN': access_token,
        Accept: 'application/json',
      },
    }).then(
      action('fetchSuccess', data => {
        this.ordersPrepare = data?.data;
        return data;
      }),
      action('fetchError', error => {
        alertHandler(error.response);
        return error;
      })
    );
  }

  setDefaultDestinationCode(destinationCode) {
    this.defaultDestinationCode = destinationCode;
  }

  async exportXLS(params) {
    let access_token = LocalStorage.getAccessToken();
    const API_URL = process.env.REACT_APP_API_URL_PROD;
    let url = `${API_URL}/staff/restaurant/shipments/${params.shipment_id}.xlsx?employee_id=${params.employee_id}&restaurant_id=${params.restaurant_id}`;

    axios({
      url,
      method: 'GET',
      responseType: 'blob',
      headers: {
        'ACCESS-TOKEN': access_token,
      },
    }).then(
      action('fetchSuccess', response => {
        let newBlob = new Blob([response.data], { type: 'application/xlsx' });
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveOrOpenBlob(newBlob);
          return;
        }
        const url = window.URL.createObjectURL(new Blob([response.data]));
        let link = document.createElement('a');
        link.href = url;
        link.download = `${params.restaurantName}_${params.destination_code}.xlsx`;
        link.click();
        setTimeout(function () {
          window.URL.revokeObjectURL(url);
        }, 100);
      }),
      action('fetchError', error => {
        alertHandler(error.response);
        return error;
      })
    );
  }

  async exportPDF(params) {
    let access_token = LocalStorage.getAccessToken();
    const API_URL = process.env.REACT_APP_API_URL_PROD;
    let url = `${API_URL}/staff/restaurant/shipments/${params.shipment_id}.pdf?employee_id=${params.employee_id}&restaurant_id=${params.restaurant_id}`;
    axios({
      url,
      method: 'GET',
      responseType: 'blob',
      headers: {
        'ACCESS-TOKEN': access_token,
      },
    }).then(
      action('fetchSuccess', response => {
        let newBlob = new Blob([response.data], { type: 'application/pdf' });
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveOrOpenBlob(newBlob);
          return;
        }
        const url = window.URL.createObjectURL(new Blob([response.data]));
        let link = document.createElement('a');
        link.href = url;
        link.download = `${params.restaurantName}_${params.destination_code}.pdf`;
        link.click();
        setTimeout(function () {
          window.URL.revokeObjectURL(url);
        }, 100);
      }),
      action('fetchError', error => {
        alertHandler(error.response);
        return error;
      })
    );
  }

  async exportLabels(params) {
    let access_token = LocalStorage.getAccessToken();
    const API_URL = process.env.REACT_APP_API_URL_PROD;
    let url = `${API_URL}/staff/restaurant/shipments/${params.shipment_id}.pdf?employee_id=${params.employee_id}&restaurant_id=${params.restaurant_id}&order_labels_export=true`;
    axios({
      url,
      method: 'GET',
      responseType: 'blob',
      headers: {
        'ACCESS-TOKEN': access_token,
      },
    }).then(
      action('fetchSuccess', response => {
        let newBlob = new Blob([response.data], { type: 'application/pdf' });
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveOrOpenBlob(newBlob);
          return;
        }
        const url = window.URL.createObjectURL(new Blob([response.data]));
        let link = document.createElement('a');
        link.href = url;
        link.download = `${params.restaurantName}_${params.destination_code}.pdf`;
        link.click();
        setTimeout(function () {
          window.URL.revokeObjectURL(url);
        }, 100);
      }),
      action('fetchError', error => {
        alertHandler(error.response);
        return error;
      })
    );
  }

  async getETA(shipmentId, params) {
    let paramsString = qs.stringify(params);
    return client()
      .get(`${api.eta()}/${shipmentId}?${paramsString}`)
      .then(
        action('fetchSuccess', data => {
          this.eta = data.data;
          return data;
        }),
        action('fetchError', error => {
          // alertHandler(error.response);
          return error;
        })
      );
  }

  async getShipmentsETA(shipmentId, params) {
    let paramsString = qs.stringify(params);
    return client()
      .get(`${api.eta()}/${shipmentId}?${paramsString}`)
      .then(
        action('fetchSuccess', data => {
          let _shipmentsETA = this.shipmentsETA.filter(item => item.shipmentId !== shipmentId);
          this.shipmentsETA = _shipmentsETA.concat({ shipmentId, eta: data.data });
          return data;
        }),
        action('fetchError', error => {
          // alertHandler(error.response);
          return error;
        })
      );
  }

  async confirmShipment(restaurantId, shipmentId, params) {
    let paramsString = qs.stringify(params);
    return client()
      .put(`${api.restaurantShipments()}/${shipmentId}?${paramsString}`, {
        restaurant_id: restaurantId,
      })
      .then(
        action('fetchSuccess', data => {
          return data;
        }),
        action('fetchError', error => {
          alertHandler(error.response);
          return error;
        })
      );
  }

  async acceptDeclineCancellation(orderId, params, approved) {
    let paramsString = qs.stringify(params);
    return client()
      .put(`${api.restaurantOrders()}/${orderId}?${paramsString}`, { approved: approved })
      .then(
        action('fetchSuccess', data => {
          return data;
        }),
        action('fetchError', error => {
          alertHandler(error.response);
          return error;
        })
      );
  }

  async getEmployees(params, perPage = 8, pageNumber = 1) {
    let _params = params;
    _params.per_page = perPage;
    if (!params.page) _params.page = pageNumber;

    let paramsString = qs.stringify(_params);
    return client()
      .get(`${api.employees()}?${paramsString}`)
      .then(
        action('fetchSuccess', data => {
          this.employees = data?.data;
          return data;
        }),
        action('fetchError', error => {
          // alertHandler(error.response);
          return error;
        })
      );
  }

  async addEmployee(params, payload) {
    let paramsString = qs.stringify(params);
    return client()
      .post(`${api.employees()}?${paramsString}`, payload)
      .then(
        action('fetchSuccess', data => {
          this.getEmployees(params);
          return data;
        }),
        action('fetchError', error => {
          alertHandler(error.response);
          return error;
        })
      );
  }

  async updateRestaurantNotifications(restaurantEmployeeId, params, payload) {
    let paramsString = qs.stringify(params);
    return client()
      .put(`${api.employees()}/${restaurantEmployeeId}?${paramsString}`, payload)
      .then(
        action('fetchSuccess', data => {
          this.getEmployees(params);
          return data;
        }),
        action('fetchError', error => {
          alertHandler(error.response);
          return error;
        })
      );
  }

  async updateEmployee(id, params, payload) {
    let paramsString = qs.stringify(params);
    return client()
      .put(`${api.employees()}/${id}?${paramsString}`, payload)
      .then(
        action('fetchSuccess', data => {
          this.getEmployees(params);
          return data;
        }),
        action('fetchError', error => {
          alertHandler(error.response);
          return error;
        })
      );
  }

  async deleteEmployee(id, params) {
    let paramsString = qs.stringify(params);
    return client()
      .delete(`${api.employees()}/${id}?${paramsString}`)
      .then(
        action('fetchSuccess', data => {
          this.getEmployees(params);
          return data;
        }),
        action('fetchError', error => {
          alertHandler(error.response);
          return error;
        })
      );
  }

  //menu editor page methods
  async getSelectorOptions(restaurantId, params, updateStore = false) {
    if (updateStore)
      this.specificMenu =
        this.specificCategory =
        this.specificItem =
        this.specificOptionGroup =
          null;

    this.optionCategories = null;
    let paramsString = qs.stringify(params);
    return client()
      .get(`${api.restaurantProfile(restaurantId)}?${paramsString}`)
      .then(
        action('fetchSuccess', data => {
          if (params.requested_for === 'menus')
            this.menusList = data?.data?.data?.map(menu => {
              return { label: menu.name, value: menu.id };
            });
          else if (params.requested_for === 'menu categories')
            this.categoriesList = data?.data?.data?.map(menu => {
              return { label: menu.extended_name, value: menu.id };
            });
          else if (params.requested_for === 'menu items')
            this.itemsList = data?.data?.data?.map(menu => {
              return { label: menu.extended_name, value: menu.id };
            });
          else if (params.requested_for === 'menu option categories')
            this.optionGroupList = data?.data?.data?.map(menu => {
              return { label: menu.extended_name, value: menu.id };
            });
          else if (params.requested_for === 'menu options')
            this.menuOptionsList = data?.data?.data?.map(menu => {
              return { label: menu.extended_name, value: menu.id };
            });

          return data;
        }),
        action('fetchError', error => {
          alertHandler({
            title: 'No Information Found',
            body: 'No user associated with this account. If you need assistance please start a live chat or email us at help@hungerhub.com.',
          });
          return error;
        })
      );
  }

  async getSpecificMenu(menuId, params) {
    let paramsString = qs.stringify(params);
    return client()
      .get(`${api.menuFilters()}/${menuId}?${paramsString}`)
      .then(
        action('fetchSuccess', data => {
          this.specificMenu = data?.data?.data;
          let categories = data?.data?.data?.attributes?.menu_categories.map(({ attributes }) => ({
            id: attributes.id,
            name: attributes.name,
          }));
          this.specificMenuCategories = categories;
          return data;
        }),
        action('fetchError', error => {
          alertHandler(error.response);
          return error;
        })
      );
  }

  async getSpecificCategory(categoryId, params, updateStore = false) {
    let paramsString = qs.stringify(params);

    return client()
      .get(`${api.menuFilters()}/${categoryId}?${paramsString}`)
      .then(
        action('fetchSuccess', data => {
          if (updateStore) this.specificCategory = data?.data?.data;
          return data;
        }),
        action('fetchError', error => {
          alertHandler(error.response);
          return error;
        })
      );
  }

  async getSpecificItem(itemId, params, updateStore = false) {
    let paramsString = qs.stringify(params);

    return client()
      .get(`${api.menuFilters()}/${itemId}?${paramsString}`)
      .then(
        action('fetchSuccess', data => {
          if (updateStore) this.specificItem = data?.data?.data;
          return data;
        }),
        action('fetchError', error => {
          alertHandler(error.response);
          return error;
        })
      );
  }

  async getSpecificOptionGroup(optionGroupId, params) {
    let paramsString = qs.stringify(params);

    return client()
      .get(`${api.menuFilters()}/${optionGroupId}?${paramsString}`)

      .then(
        action('fetchSuccess', data => {
          this.specificOptionGroup = data?.data?.data;
          return data;
        }),
        action('fetchError', error => {
          alertHandler(error.response);
          return error;
        })
      );
  }

  async getSpecificOption(optionId, params) {
    let paramsString = qs.stringify(params);

    return client()
      .get(`${api.menuFilters()}/${optionId}?${paramsString}`)

      .then(
        action('fetchSuccess', data => {
          this.specificOption = data?.data?.data;
          return data;
        }),
        action('fetchError', error => {
          alertHandler(error.response);
          return error;
        })
      );
  }

  clearSpecificCategory() {
    this.specificCategory = null;
  }

  clearSpecificItem() {
    this.specificItem = null;
  }

  clearSpecificOptionGroup() {
    this.specificOptionGroup = null;
  }

  clearSpecificOption() {
    this.specificOption = null;
  }

  clearSpecificMenu() {
    this.specificMenu = null;
    this.specificMenuCategories = [];
  }

  updateCategory(categoryId, payload, params) {
    let paramsString = qs.stringify(params);
    return client()
      .put(`${api.menuCategories()}/${categoryId}?${paramsString}`, payload)
      .then(
        action('fetchSuccess', data => {
          this.forceUpdateOptionCategories = true; //to refresh the items within the category
          return data;
        }),
        action('fetchError', error => {
          alertHandler(error.response);
          return error;
        })
      );
  }

  createCategory(payload, params) {
    let paramsString = qs.stringify(params);
    return client()
      .post(`${api.menuCategories()}?${paramsString}`, payload)
      .then(
        action('fetchSuccess', data => {
          return data;
        }),
        action('fetchError', error => {
          const errors = error?.response?.data?.errors;
          alertHandler({
            title: array.isPresent(errors) ? errors[0] : '',
          });
          return error;
        })
      );
  }

  updatePositions(restaurantId, payload, params) {
    let paramsString = qs.stringify(params);
    return client()
      .put(`${api.restaurantProfile(restaurantId)}?${paramsString}`, payload)
      .then(
        action('fetchSuccess', data => {
          return data;
        }),
        action('fetchError', error => {
          alertHandler(error.response);
          return error;
        })
      );
  }

  getItemBadges(params) {
    let paramsString = qs.stringify(params);
    return client()
      .get(`${api.badges()}?${paramsString}`)
      .then(
        action('fetchSuccess', data => {
          this.itemBadges = data?.data?.data?.map(badge => {
            return { label: badge, value: { name: badge } };
          });
          return data;
        }),
        action('fetchError', error => {
          alertHandler(error.response);
          return error;
        })
      );
  }

  updateItem(itemId, payload, params) {
    let paramsString = qs.stringify(params);
    return client()
      .put(`${api.menuItems()}/${itemId}?${paramsString}`, payload)
      .then(
        action('fetchSuccess', data => {
          return data;
        }),
        action('fetchError', error => {
          alertHandler(error.response);
          return error;
        })
      );
  }

  createItem(payload, params) {
    let categoryId = payload?.menu_category_ids[0];
    let paramsString = qs.stringify(params);
    return client()
      .post(`${api.menuItems()}?${paramsString}`, payload)
      .then(
        action('fetchSuccess', data => {
          if (this.specificMenu) {
            //to locally push the new item into the menu
            let specificMenu = toJS(this.specificMenu);
            this.specificMenu = {
              ...specificMenu,
              attributes: {
                ...specificMenu.attributes,
                menu_categories: specificMenu.attributes.menu_categories?.map(category => {
                  if (parseInt(category.id) === categoryId) {
                    category.attributes.menu_items.push(data?.data?.data[0]);
                  }
                  return category;
                }),
              },
            };
          }
          if (this.specificCategory) {
            // to locally push the new item to the category
            let specificCategory = toJS(this.specificCategory);
            let updatedCategory = {
              ...specificCategory,
              attributes: {
                ...specificCategory.attributes,
                menu_items: [...specificCategory.attributes.menu_items, data?.data?.data[0]],
              },
            };
            this.specificCategory = updatedCategory;
          }
          return data;
        }),
        action('fetchError', error => {
          const errors = error?.response?.data?.errors;
          alertHandler({
            title: array.isPresent(errors) ? errors[0] : '',
          });
          return error;
        })
      );
  }

  updateOptionGroup(optionGroupId, payload, params) {
    let paramsString = qs.stringify(params);
    return client()
      .put(`${api.optionGroups()}/${optionGroupId}?${paramsString}`, payload)
      .then(
        action('fetchSuccess', data => {
          this.forceUpdateOptionCategories = true;
          return data;
        }),
        action('fetchError', error => {
          alertHandler(error.response);
          return error;
        })
      );
  }
  createOptionGroup(payload, params) {
    let paramsString = qs.stringify(params);
    return client()
      .post(`${api.optionGroups()}?${paramsString}`, payload)
      .then(
        action('fetchSuccess', data => {
          let optionCategories = toJS(this.optionCategories);
          if (optionCategories)
            this.optionCategories = {
              ...optionCategories,
              data: {
                data: {
                  ...optionCategories.data.data,
                  attributes: {
                    ...optionCategories.data.data.attributes,
                    option_categories: [
                      ...optionCategories.data.data.attributes.option_categories,
                      data?.data?.data[0],
                    ],
                  },
                },
              },
            };
          return data;
        }),
        action('fetchError', error => {
          const errors = error?.response?.data?.errors;
          alertHandler({
            title: array.isPresent(errors) ? errors[0] : '',
          });
          return error;
        })
      );
  }

  updateOption(optionId, payload, params) {
    let paramsString = qs.stringify(params);
    return client()
      .put(`${api.options()}/${optionId}?${paramsString}`, payload)
      .then(
        action('fetchSuccess', data => {
          return data;
        }),
        action('fetchError', error => {
          alertHandler(error.response);
          return error;
        })
      );
  }

  createOption(payload, params) {
    this.forceUpdateOptionCategories = false;
    let optionCategoryId = payload?.option_category_ids[0];
    let paramsString = qs.stringify(params);
    return client()
      .post(`${api.options()}?${paramsString}`, payload)
      .then(
        action('fetchSuccess', data => {
          let optionCategories = toJS(this.optionCategories);
          let specificOptionGroup = toJS(this.specificOptionGroup);
          this.forceUpdateOptionCategories = true;
          if (optionCategories)
            this.optionCategories = {
              ...optionCategories,
              data: {
                data: {
                  ...optionCategories.data.data,
                  attributes: {
                    ...optionCategories.data.data.attributes,
                    option_categories: optionCategories.data.data.attributes.option_categories?.map(
                      optionCategory => {
                        if (parseInt(optionCategory.id) === optionCategoryId) {
                          optionCategory.attributes.options.push(data?.data?.data[0]);
                        }
                        return optionCategory;
                      }
                    ),
                  },
                },
              },
            };
          if (specificOptionGroup) {
            this.specificOptionGroup = {
              ...specificOptionGroup,
              attributes: {
                ...specificOptionGroup.attributes,
                options: [...specificOptionGroup.attributes.options, data?.data?.data[0]],
              },
            };
          }
          return data;
        }),
        action('fetchError', error => {
          const errors = error?.response?.data?.errors;
          alertHandler({
            title: array.isPresent(errors) ? errors[0] : '',
          });
          return error;
        })
      );
  }

  async createPresignedUrl(file, byte_size, checksum, params) {
    let payload = {
      file: {
        filename: file.name,
        byte_size: byte_size,
        checksum: checksum,
        content_type: 'application/png',
        metadata: {
          message: 'item image',
        },
      },
    };

    let paramsString = qs.stringify(params);
    return client()
      .post(`${api.preSignedUrl()}?${paramsString}`, payload)
      .then(
        action('fetchSuccess', data => {
          return data;
        }),
        action('fetchError', error => {
          alertHandler(error.response);
          return error;
        })
      );
  }

  async getEntireMenus(params) {
    let paramsString = qs.stringify(params);
    return client()
      .get(`${api.menus()}?${paramsString}`)
      .then(
        action('fetchSuccess', data => {
          let helper = data?.data?.data?.map(menu => {
            return {
              value: menu?.id,
              label: menu?.attributes?.name,
              restaurant: menu?.attributes?.restaurant_name,
            };
          });
          this.entireMenus = helper;
          return data;
        }),
        action('fetchError', error => {
          alertHandler(error.response);
          return error;
        })
      );
  }

  async getReportsHistory(params) {
    let paramsString = qs.stringify(params);
    return client()
      .get(`${api.reports()}?${paramsString}`)
      .then(
        action('fetchSuccess', data => {
          this.reportHistory = data?.data;
          return data;
        }),
        action('fetchError', error => {
          alertHandler(error.response);
          return error;
        })
      );
  }

  async getFornightlyStatus(id, params) {
    let paramsString = qs.stringify(params);
    return client()
      .get(`${api.restaurants()}/${id}?${paramsString}`)
      .then(
        action('fetchSuccess', data => {
          this.fortnightlyStatus = data?.data;
          return data;
        }),
        action('fetchError', error => {
          alertHandler(error.response);
          return error;
        })
      );
  }

  async updateFortnightlyStatus(id, params) {
    let paramsString = qs.stringify(params);
    return client()
      .put(`${api.restaurantProfile(id)}?${paramsString}`)
      .then(
        action('fetchSuccess', data => {
          return data;
        }),
        action('fetchError', error => {
          alertHandler(error.response);
          return error;
        })
      );
  }

  async createNewReport(emails, restaurantId, employeeId, startDate, endDate, paymentDate) {
    const payload = {
      emails,
      restaurant_id: restaurantId,
      employee_id: employeeId,
      start_date: startDate,
      end_date: endDate,
      payment_date: paymentDate,
    };

    return client()
      .post(api.reports(), payload)
      .then(
        action('fetchSuccess', data => {
          alertHandler({
            title: data?.data?.message,
          });
          return data;
        }),
        action('fetchError', error => {
          alertHandler({
            title: 'Unable to generate sales report',
            body: 'If you need assistance please start a live chat or email us at help@hungerhub.com.',
          });
          return error;
        })
      );
  }
}

export default RestaurantManagementStore;
