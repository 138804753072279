import React from 'react';

function SubsidySelector({ subsidyType, dailySubsidies, dispatch, provider }) {
  return (
    <div>
      <div className="d-col mt-5">
        <small className="text-black text-sm font-inter-regular">Subsidy Type</small>
        <div className="d-row mt-3">
          <div className="d-row mr-4">
            <label className="inline-flex items-center">
              <input
                autoComplete="off"
                type="radio"
                className="form-radio"
                name="radio"
                value="daily"
                checked={subsidyType === 'daily'}
                onClick={e => dispatch({ field: 'subsidyType', value: e.target.value })}
              />
              <span className="ml-2 text-xs font-inter-regular">Daily</span>
            </label>
          </div>
          <div className="d-row">
            <label className="inline-flex items-center">
              <input
                autoComplete="off"
                type="radio"
                className="form-radio"
                name="radio"
                value="weekly"
                checked={subsidyType === 'weekly'}
                onClick={e => dispatch({ field: 'subsidyType', value: e.target.value })}
              />
              <span className="ml-2 text-xs font-inter-regular">Weekly</span>
            </label>
          </div>
        </div>
      </div>

      <div className="d-col mt-5">
        {subsidyType === 'daily' ? (
          <div className="d-row flex flex-wrap">
            {['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'].map(
              item => (
                <div className="mr-2 w-1/4">
                  <small className="input-label capitalize">{item}</small>
                  <input
                    autoComplete="off"
                    className="input-light"
                    name={item}
                    id={item}
                    min={0}
                    value={dailySubsidies.find(subsidy => subsidy.name === item)?.value ?? ''}
                    onWheel={event => event.currentTarget.blur()}
                    onChange={e => {
                      dispatch({
                        field: 'dailySubsidies',
                        value: [
                          ...dailySubsidies.filter(subsidy => subsidy.name !== e.target.id),
                          { name: e.target.id, value: e.target.value },
                        ],
                      });
                    }}
                    step={'0.01'}
                    type="number"
                  />
                </div>
              )
            )}
          </div>
        ) : (
          <input
            autoComplete="off"
            className="input-light w-1/4"
            name="weeklySubsidy"
            id="weeklySubsidy"
            min={0}
            onWheel={event => event.currentTarget.blur()}
            onChange={e => dispatch({ field: e.target.name, value: e.target.value })}
            type="number"
            step={'0.01'}
            required
            value={provider.weeklySubsidy ?? ''}
          />
        )}
      </div>
    </div>
  );
}
export default SubsidySelector;
