export default {
  login: () => `/sessions`,
  restaurants: () => '/staff/restaurants',
  profile: (id, employeeId) => `/profiles/${id}?employee_id=${employeeId}`, //to get & update the logged in user profile
  forgotPassword: () => `/passwords/new`,
  resetPassword: () => '/passwords',
  markets: () => `/staff/markets`,
  zones: () => `/staff/zones`,
  bankingInformation: restaurantId => `/staff/restaurant/banking_information/${restaurantId}`,
  accountingEmails: restaurantId => `/staff/restaurant/accounting_emails/${restaurantId}`,
  salesReport: () => `/staff/restaurant/reports`,
  restaurantProfile: restaurantId => `/staff/restaurant/profiles/${restaurantId}`,
  menuFilters: () => `/staff/restaurant/filters`,
  schedules: () => `/staff/restaurant/schedules`,
  allSchedules: () => `/staff/schedules`,
  menus: () => `/staff/restaurant/menus`,
  optionCategories: itemId => `/staff/restaurant/menu_items/${itemId}`,
  tags: () => `/staff/restaurant/tags`,
  restaurantShipments: () => `/staff/restaurant/shipments`,
  restaurantOrders: () => `/staff/restaurant/orders`,
  clients: () => '/staff/locations',
  zoneLocations: () => `/staff/zone_locations`,
  meals: () => `/staff/meals`,
  locationRestaurants: () => `/staff/location_menus`,
  mealSeries: () => `/staff/meal_series`,
  meal: () => `/staff/meals`,
  eta: () => `/staff/restaurant/estimated_arrivals`,
  employees: () => `/staff/restaurant/employees`,
  officeEmployees: () => `/staff/company/employees`,
  menuCategories: () => `/staff/restaurant/menu_categories`,
  menuItems: () => `/staff/restaurant/menu_items`,
  badges: () => `/staff/restaurant/badges`,
  preSignedUrl: () => `/presigned_urls`,
  optionGroups: () => `/staff/restaurant/option_categories`,
  options: () => `/staff/restaurant/options`,
  associatedAccounts: userId => `/staff/associated_accounts/${userId}`,
  reports: () => `/staff/reports`,

  staff: () => `/staff/employees`,
  staffShipments: () => `/staff/shipments`,
  teams: () => `/staff/company/teams`,
  officeEmployee: () => `/staff/company/employees`,
  officeUserCredit: userId => `/staff/company/adjustments`,
  officeUserOrder: () => `/staff/user_orders`,
  cateringOrders: () => `/staff/orders`,

  //user management
  users: () => `/staff/users`,
  userDetail: userId => `/staff/users/${userId}`,
  creditCards: () => `/staff/company/credit_cards`,
  companyMenus: () => `/staff/company/menus`,
  menuOptions: () => `/staff/company/option_categories`,
  userOrders: () => `/staff/user_orders`,

  //account management
  orders: () => `/staff/company/orders`,
  companyEmployees: () => `/staff/company/employees`,
  companyETA: () => `/staff/company/estimated_arrivals`,
  officeTeams: () => `/staff/company/teams`,
  employeeTeams: () => `/staff/company/employee_teams`,
  teamAssignments: () => `/staff/company/team_assignments`,
  companyProfile: () => `/staff/company/profiles`,
  locationMenus: () => `/staff/company/location_menus`,
  fetchUser: email => `/profiles/${email}`,
  accountBillings: () => `/staff/account_management_billings`,
  invoiceReport: () => `/staff/company/invoices`,

  //placeOrder
  orderMenus: userId => `/staff/company/employees/${userId}/menus`,
  mealMenu: mealId => `/staff/company/menus/${mealId}`,
  operatingHoursAvailable: id => `/staff/operating_hours/${id}`,

  //market management

  //delivery management
  deliveryLocations: () => `/staff/locations`,
  onFleetTask: () => `/staff/tasks`,
  ODCuncateringOrders: () => `/staff/orders`,
  notifyUsers: () => `/staff/user_notifications`,
  shipmentTask: () => `/staff/shipment_tasks`,
  deliveryTimes: () => `/staff/delivery_times`,
};
