const isLoggedIn = () => {
  const authToken = localStorage.getItem('auth_token');
  return authToken ? true : false;
};

const setAccessToken = token => {
  localStorage?.setItem('auth_token', token);
};

const getAccessToken = () => {
  return localStorage?.getItem('auth_token') || null;
};

const removeKey = key => {
  localStorage?.removeItem(key);
};

const setKeyValue = (key, value) => {
  localStorage?.setItem(key, value);
};

export default { isLoggedIn, setAccessToken, getAccessToken, removeKey, setKeyValue };
