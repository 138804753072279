import { makeObservable, observable, action } from 'mobx';
import { makePersistable, clearPersistedStore } from 'mobx-persist-store';
import { client } from '../store';
import api from '../store/api';
import qs from 'qs';
import { alertHandler } from 'utils/middlewares/alertHandler';
import { toJS } from 'mobx';
import accounts from 'utils/helpers/accounts';
import dateTime from 'utils/helpers/dateTime';
import moment from 'moment';
import validation from 'utils/helpers/validation';

class MealManagementStore {
  markets = [];
  zones = [];
  clients = null;
  clientsPagy = null;
  meals = null;
  organisedMeals = [];
  locationRestaurants = [];
  selectedRestaurantList = [];
  selectedMarket = null;
  selectedZone = null;
  selectedLocation = null;
  selectedDate = moment().toDate();
  updatedResponse = [];

  selectedMarket = null;
  selectedZone = null;
  selectedLocation = null;
  selectedDate = moment().toDate();
  updatedResponse = [];
  navigationWarning = false;

  //for the copy meal feature
  copyMealLocations = [];
  copyLocationMealsFrom = [];

  constructor() {
    makeObservable(this, {
      markets: observable,
      zones: observable,
      clients: observable,
      meals: observable,
      organisedMeals: observable,
      locationRestaurants: observable,
      selectedRestaurantList: observable,
      selectedMarket: observable,
      selectedZone: observable,
      selectedDate: observable,
      selectedLocation: observable,
      updatedResponse: observable,
      navigationWarning: observable,

      copyMealLocations: observable,
      copyLocationMealsFrom: observable,
      clientsPagy: observable,
    });
  }

  async getMarkets(params) {
    let paramsString = qs.stringify(params);
    return client()
      .get(`${api.markets()}?${paramsString}`)
      .then(
        action('fetchSuccess', data => {
          this.markets = data?.data;
        }),
        action('fetchError', error => {
          alertHandler(error.response);
          return error;
        })
      );
  }

  async getZones(params) {
    let paramsString = qs.stringify(params);
    return client()
      .get(`${api.zones()}?${paramsString}`)
      .then(
        action('fetchSuccess', data => {
          this.zones = data?.data;
        }),
        action('fetchError', error => {
          alertHandler(error.response);
          return error;
        })
      );
  }

  async getClients(params, paginationApplied = false) {
    let paramsString = qs.stringify(params);
    return client()
      .get(`${api.zoneLocations()}?${paramsString}`)
      .then(
        action('fetchSuccess', data => {
          if (paginationApplied) {
            let loadData = data?.data?.locations?.map(({ id, name, status, tzinfo }) => {
              return {
                label: name,
                value: id,
                status,
                tzinfo,
              };
            });
            this.clients = [...this.clients, loadData]?.flat();
          } else
            this.clients = data?.data?.locations?.map(({ id, name, status, tzinfo }) => {
              return {
                label: name,
                value: id,
                status,
                tzinfo,
              };
            });
          this.clientsPagy = data?.data?.pagy;
        }),
        action('fetchError', error => {
          alertHandler(error.response);
          return error;
        })
      );
  }

  async resetMeals() {
    this.meals = null;
    this.organisedMeals = [];
  }

  async getMeals(params) {
    let sortedData = [];
    let paramsString = qs.stringify(params);
    this.setNavigationWarning(false);
    this.setUpdatedResponse([]);
    return client()
      .get(`${api.meals()}?${paramsString}`)
      .then(
        action('fetchSuccess', data => {
          this.meals = data?.data;

          data?.data?.data?.map(
            ({
              attributes: {
                id,
                name,
                meal_date,
                cutoff,
                expected_bags,
                meal_series_id,
                menus,
                shipments,
                target_time,
                meal_series_cutoff,
                meal_series_expected_bags,
                meal_series_target_time,
                disabled,
              },
            }) => {
              let foundIndex = sortedData.findIndex(x => x.date === meal_date);

              if (foundIndex > -1) {
                let meal = sortedData[foundIndex].meal;
                sortedData[foundIndex].meal = [
                  ...meal,
                  {
                    id,
                    tempId: id ? id : validation.generateId(),
                    name,
                    meal_date,
                    cutoff,
                    expected_bags,
                    meal_series_id,
                    menus,
                    shipments,
                    target_time,
                    meal_series_cutoff,
                    meal_series_expected_bags,
                    meal_series_target_time,
                    disabled,
                  },
                ];
              } else {
                sortedData.push({
                  date: meal_date,
                  meal: [
                    {
                      id,
                      tempId: id ? id : validation.generateId(),
                      name,
                      meal_date,
                      cutoff,
                      expected_bags,
                      meal_series_id,
                      menus,
                      shipments,
                      target_time,
                      meal_series_cutoff,
                      meal_series_expected_bags,
                      meal_series_target_time,
                      disabled,
                    },
                  ],
                });
              }
            }
          );

          this.organisedMeals = sortedData.sort(function (a, b) {
            return new Date(a.date) - new Date(b.date);
          });

          this.setUpdatedResponse(
            sortedData.sort(function (a, b) {
              return new Date(a.date) - new Date(b.date);
            })
          );
          return data?.data?.data;
        }),
        action('fetchError', error => {
          alertHandler(error.response);
          return error;
        })
      );
  }

  async getMealsMobile(params) {
    let sortedData = [];
    let paramsString = qs.stringify(params);
    return client()
      .get(`${api.meals()}?${paramsString}`)
      .then(
        action('fetchSuccess', data => {
          this.meals = data?.data;

          data?.data?.data?.map(
            ({
              attributes: {
                id,
                name,
                meal_date,
                cutoff,
                expected_bags,
                meal_series_id,
                menus,
                shipments,
                target_time,
                meal_series_cutoff,
                meal_series_expected_bags,
                meal_series_target_time,
                disabled,
              },
            }) => {
              let foundIndex = sortedData.findIndex(x => x.date === meal_date);

              if (foundIndex > -1) {
                let meal = sortedData[foundIndex].meal;
                sortedData[foundIndex].meal = [
                  ...meal,
                  {
                    id,
                    tempId: id ? id : validation.generateId(),
                    name,
                    meal_date,
                    cutoff,
                    expected_bags,
                    meal_series_id,
                    menus,
                    shipments,
                    target_time,
                    meal_series_cutoff,
                    meal_series_expected_bags,
                    meal_series_target_time,
                    disabled,
                  },
                ];
              } else {
                sortedData.push({
                  date: meal_date,
                  meal: [
                    {
                      id,
                      tempId: id ? id : validation.generateId(),
                      name,
                      meal_date,
                      cutoff,
                      expected_bags,
                      meal_series_id,
                      menus,
                      shipments,
                      target_time,
                      meal_series_cutoff,
                      meal_series_expected_bags,
                      meal_series_target_time,
                      disabled,
                    },
                  ],
                });
              }
            }
          );

          this.organisedMeals = sortedData.sort(function (a, b) {
            return new Date(a.date) - new Date(b.date);
          });

          this.setUpdatedResponse(
            sortedData.sort(function (a, b) {
              return new Date(a.date) - new Date(b.date);
            })
          );
          return data?.data?.data;
        }),
        action('fetchError', error => {
          alertHandler(error.response);
          return error;
        })
      );
  }

  async getLocationRestaurants(params) {
    let paramsString = qs.stringify(params);
    return client()
      .get(`${api.locationRestaurants()}?${paramsString}`)
      .then(
        action('fetchSuccess', data => {
          this.locationRestaurants = data?.data;
          return data?.data;
        }),
        action('fetchError', error => {
          alertHandler(error.response);
          return error;
        })
      );
  }

  async createMealSeries(params, payload) {
    let paramsString = qs.stringify(params);
    return client()
      .post(`${api.mealSeries()}?${paramsString}`, payload)
      .then(
        action('fetchSuccess', data => {
          return data?.data;
        }),
        action('fetchError', error => {
          alertHandler(error.response);
          return error;
        })
      );
  }

  async updateMealSeries(mealSeriesId, params, payload) {
    let paramsString = qs.stringify(params);
    return client()
      .put(`${api.mealSeries()}/${mealSeriesId}?${paramsString}`, payload)
      .then(
        action('fetchSuccess', data => {
          return data?.data;
        }),
        action('fetchError', error => {
          alertHandler(error.response);
          return error;
        })
      );
  }

  async getMealSeries(mealSeriesId, params) {
    let paramsString = qs.stringify(params);
    return client()
      .get(`${api.mealSeries()}/${mealSeriesId}?${paramsString}`)
      .then(
        action('fetchSuccess', data => {
          return data?.data;
        }),
        action('fetchError', error => {
          alertHandler(error.response);
          return error;
        })
      );
  }

  async updateMeal(mealId, params, payload) {
    let paramsString = qs.stringify(params);
    return client()
      .put(`${api.meal()}/${mealId}?${paramsString}`, payload)
      .then(
        action('fetchSuccess', data => {
          return data?.data;
        }),
        action('fetchError', error => {
          alertHandler(error.response);
          return error;
        })
      );
  }

  async createMeal(params, payload) {
    let paramsString = qs.stringify(params);
    return client()
      .post(`${api.meal()}?${paramsString}`, payload)
      .then(
        action('fetchSuccess', data => {
          return data?.data;
        }),
        action('fetchError', error => {
          alertHandler(error.response);
          return error;
        })
      );
  }

  async updateBulkMeals(locationId, params, payload) {
    let paramsString = qs.stringify(params);
    return client()
      .put(`${api.clients()}/${locationId}?${paramsString}`, payload)
      .then(
        action('fetchSuccess', data => {
          return data?.data;
        }),
        action('fetchError', error => {
          alertHandler(error.response);
          return error;
        })
      );
  }

  async deleteOrRecoverRestaurant(shipmentId, params, payload) {
    let paramsString = qs.stringify(params);
    return client()
      .put(`${api.staffShipments()}/${shipmentId}?${paramsString}`, payload)
      .then(
        action('fetchSuccess', data => {
          return data?.data;
        }),
        action('fetchError', error => {
          alertHandler(error.response);
          return error;
        })
      );
  }

  async deleteSavedShipment(shipmentId, params) {
    let paramsString = qs.stringify(params);
    return client()
      .delete(`${api.staffShipments()}/${shipmentId}?${paramsString}`)
      .then(
        action('fetchSuccess', data => {
          return data?.data;
        }),
        action('fetchError', error => {
          alertHandler(error.response);
          return error;
        })
      );
  }

  async publishSchedules(params, payload) {
    let paramsString = qs.stringify(params);
    return client()
      .post(`${api.staffShipments()}?${paramsString}`, payload)
      .then(
        action('fetchSuccess', data => {
          return data?.data;
        }),
        action('fetchError', error => {
          alertHandler(error.response);
          return error;
        })
      );
  }

  setSelectedRestaurantList(list) {
    this.selectedRestaurantList = list;
  }

  setSelectedMarket(selected) {
    this.selectedMarket = selected;
  }

  setSelectedZone(selected) {
    this.selectedZone = selected;
  }

  setSelectedDate(date) {
    this.selectedDate = date;
  }
  setSelectedLocation(location) {
    this.selectedLocation = location;
  }

  setUpdatedResponse(response) {
    this.updatedResponse = response;
  }

  resetChildren(parent) {
    if (parent === 'date') {
      this.selectedMarket = null;
      this.selectedZone = null;
      this.selectedLocation = null;
      this.updatedResponse = [];
    } else if (parent === 'market') {
      this.selectedZone = null;
      this.selectedLocation = null;
      this.updatedResponse = [];
    } else if (parent === 'zone') {
      this.selectedLocation = null;
      this.updatedResponse = [];
    }
  }

  setNavigationWarning(value) {
    this.navigationWarning = value;
  }

  async getCopyMealLocations(params) {
    let paramsString = qs.stringify(params);
    const selectedLocation = toJS(this.selectedLocation);
    return client()
      .get(`${api.zoneLocations()}?${paramsString}`)
      .then(
        action('fetchSuccess', data => {
          this.copyMealLocations = data?.data?.data
            ?.filter(location => Number(location?.id) !== Number(selectedLocation?.value))
            ?.map(({ id, attributes: { name, status } }) => {
              return { label: name, value: id, status };
            });
        }),
        action('fetchError', error => {
          alertHandler(error.response);
          return error;
        })
      );
  }

  async getCopyLocationMealsFrom(params) {
    let paramsString = qs.stringify(params);

    return client()
      .get(`${api.meals()}?${paramsString}`)
      .then(
        action('fetchSuccess', data => {
          let helperArray = data?.data?.data?.filter(meal => meal?.id);
          this.copyLocationMealsFrom = helperArray?.map(meal => {
            return {
              label: `${meal?.attributes?.name} - ${
                meal?.id && meal?.attributes?.cutoff
                  ? dateTime.formatTime(meal?.attributes?.cutoff) // HOT FIX pass here time zone of selected market or zone
                  : dateTime.to12hourformat(meal?.attributes?.meal_series_cutoff) // HOT FIX pass here time zone of selected market or zone
              } - ${
                meal.id
                  ? meal?.attributes?.expected_bags || '0'
                  : meal?.attributes?.meal_series_expected_bags || '0'
              }`,
              value: meal?.attributes?.id,
              mealDate: meal?.attributes?.meal_date,
            };
          });

          return data?.data?.data;
        }),
        action('fetchError', error => {
          alertHandler(error.response);
          return error;
        })
      );
  }

  async copyMeal(params, payload) {
    let paramsString = qs.stringify(params);
    return client()
      .put(`${api.meal()}/${1}?${paramsString}`, payload)
      .then(
        action('fetchSuccess', data => {
          return data?.data;
        }),
        action('fetchError', error => {
          alertHandler(error.response);
          return error;
        })
      );
  }
}

export default MealManagementStore;
