import React from 'react';

// a function to retry loading a chunk to avoid chunk load error for out of date code
const lazyRetry = function (componentImport) {
  return new Promise((resolve, reject) => {
    // check if the window has already been refreshed
    const hasRefreshed = JSON.parse(
      window.sessionStorage.getItem('retry-lazy-refreshed') || 'false'
    );
    // try to import the component
    componentImport()
      .then(component => {
        window.sessionStorage.setItem('retry-lazy-refreshed', 'false'); // success so reset the refresh
        resolve(component);
      })
      .catch(error => {
        if (!hasRefreshed) {
          // not been refreshed yet
          window.sessionStorage.setItem('retry-lazy-refreshed', 'true'); // we are now going to refresh
          return window.location.reload(); // refresh the page
        }
        reject(error); // Default error behaviour as already tried refresh
      });
  });
};

const StaffManagement = React.lazy(() =>
  lazyRetry(() => import('containers/StaffManagement/StaffManagement'))
);

const RestaurantManagement = React.lazy(() =>
  lazyRetry(() => import('containers/RestaurantManagement/RestaurantManagement'))
);
const PlaceOrder = React.lazy(() => lazyRetry(() => import('containers/PlaceOrder')));
const RestaurantSchedules = React.lazy(() =>
  lazyRetry(() => import('containers/RestaurantSchedules'))
);
const CateringOrders = React.lazy(() => lazyRetry(() => import('containers/CateringOrders')));
const RestaurantSearch = React.lazy(() =>
  lazyRetry(() => import('containers/RestaurantManagement/RestaurantSearch'))
);
const RestaurantDetail = React.lazy(() =>
  lazyRetry(() => import('containers/RestaurantManagement/RestaurantDetail'))
);
const RestaurantMenuEditor = React.lazy(() =>
  lazyRetry(() => import('containers/RestaurantManagement/RestaurantMenuEditor'))
);

const AccountManagement = React.lazy(() =>
  lazyRetry(() => import('containers/AccountManagement/AccountManagement'))
);
const AccountSearch = React.lazy(() =>
  lazyRetry(() => import('containers/AccountManagement/AccountSearch'))
);
const AccountDetail = React.lazy(() =>
  lazyRetry(() => import('containers/AccountManagement/AccountDetail'))
);
const OrderDetail = React.lazy(() =>
  lazyRetry(() => import('containers/AccountManagement/Tabs/OrderDetail'))
);

const RestaurantTagManagement = React.lazy(() =>
  lazyRetry(() => import('containers/RestaurantTagManagement/RestaurantTagManagement'))
);

const UserManagement = React.lazy(() =>
  lazyRetry(() => import('containers/UserManagement/UserManagement'))
);
const UserSearch = React.lazy(() =>
  lazyRetry(() => import('containers/UserManagement/UserSearch'))
);
const UserDetail = React.lazy(() =>
  lazyRetry(() => import('containers/UserManagement/UserDetail'))
);
const EditBillingInfo = React.lazy(() =>
  lazyRetry(() => import('containers/UserManagement/EditBillingInfo'))
);

const MarketMgmt = React.lazy(() =>
  lazyRetry(() => import('containers/MarketManagement/MarketMgmt'))
);

const AccountingMgmtClient = React.lazy(() =>
  lazyRetry(() => import('containers/AccountingMgmtClient/AccountingMgmtClient'))
);

const AccountingMgmtRestaurant = React.lazy(() =>
  lazyRetry(() => import('containers/AccountingMgmtRestaurant/AccountingMgmtRestaurant'))
);

const AdminMealManagement = React.lazy(() =>
  lazyRetry(() => import('containers/AdminMealManagement/AdminMealManagement'))
);

const DeliveryMgmt = React.lazy(() =>
  lazyRetry(() => import('containers/DeliveryMgmt/DeliveryMgmt'))
);
const DeliveryDetail = React.lazy(() =>
  lazyRetry(() => import('containers/DeliveryMgmt/DeliveryDetail'))
);
const DeliveryDetailZone = React.lazy(() =>
  lazyRetry(() => import('containers/DeliveryMgmt/DeliveryDetailZone'))
);
const DeliveryRestaurantDetail = React.lazy(() =>
  lazyRetry(() => import('containers/DeliveryMgmt/DeliveryRestaurantDetail'))
);
const DeliveryDetailLocation = React.lazy(() =>
  lazyRetry(() => import('containers/DeliveryMgmt/DeliveryDetailLocation'))
);
const OfficeUserOrderDetail = React.lazy(() =>
  lazyRetry(() => import('containers/DeliveryMgmt/OfficeUserOrderDetail'))
);
const DeliveryDetailTime = React.lazy(() =>
  lazyRetry(() => import('containers/DeliveryMgmt/DeliveryDetailTime'))
);

export default {
  operations: {
    routes: [
      //Delivery Management VIEW/EDIT
      {
        component: DeliveryMgmt,
        url: '/delivery-management',
      },
      {
        component: DeliveryDetail,
        url: '/delivery-detail',
      },
      {
        component: DeliveryDetailZone,
        url: '/delivery-detail-zone',
      },
      {
        component: DeliveryRestaurantDetail,
        url: '/delivery-detail-restaurant',
      },
      {
        component: DeliveryDetailLocation,
        url: '/delivery-detail-location',
      },
      { component: OfficeUserOrderDetail, url: '/office-user-order/:id' },
      { component: DeliveryDetailTime, url: '/delivery-detail-time' },

      //Restaurant Management VIEW
      {
        component: RestaurantManagement,
        url: '/restaurant-management',
      },
      {
        component: RestaurantSearch,
        url: '/restaurant-search',
      },
      {
        component: RestaurantDetail,
        url: '/restaurant-detail/:id',
      },
      { component: RestaurantMenuEditor, url: '/restaurant-menu-editor/:id' },

      //User Management VIEW
      {
        component: UserManagement,
        url: '/user-management',
      },
      {
        component: UserSearch,
        url: '/user-search',
      },
      {
        component: UserDetail,
        url: '/user-detail/:id',
      },
      {
        component: EditBillingInfo,
        url: '/order-billing-info/:id',
      },

      //Account Management VIEW
      {
        component: AccountManagement,
        url: '/account-management',
      },
      {
        component: AccountSearch,
        url: '/account-search',
      },
      {
        component: AccountDetail,
        url: '/account-detail/:id',
      },
      {
        component: OrderDetail,
        url: '/restaurant-orders/:id',
      },

      //Meal Management VIEW
      {
        component: AdminMealManagement,
        url: '/admin-meal-management',
      },
    ],
  },
  hungerhub_super_admin: {
    routes: [
      //Team Management VIEW/EDIT
      {
        component: StaffManagement,
        url: '/staff-management',
      },

      //Meal Management VIEW/EDIT
      {
        component: AdminMealManagement,
        url: '/admin-meal-management',
      },

      //Accounting Management Client/Restaurant VIEW/EDIT
      {
        component: AccountingMgmtClient,
        url: '/accounting-management-client',
      },
      {
        component: AccountingMgmtRestaurant,
        url: '/accounting-management-restaurant',
      },

      //Place Order VIEW/EDIT
      {
        component: PlaceOrder,
        url: '/place-order',
      },
      {
        component: RestaurantSchedules,
        url: '/restaurant-schedules',
      },
      {
        component: CateringOrders,
        url: '/catering-orders',
      },

      //Restaurant Tag Management VIEW/EDIT
      {
        component: RestaurantTagManagement,
        url: '/restaurant-tag-management',
      },

      //Market Management VIEW/EDIT
      {
        component: MarketMgmt,
        url: '/market-management',
      },

      //User Management VIEW/EDIT
      {
        component: UserManagement,
        url: '/user-management',
      },
      {
        component: UserSearch,
        url: '/user-search',
      },
      {
        component: UserDetail,
        url: '/user-detail/:id',
      },
      {
        component: EditBillingInfo,
        url: '/order-billing-info/:id',
      },

      //Restaurant Management VIEW/EDIT
      {
        component: RestaurantManagement,
        url: '/restaurant-management',
      },
      {
        component: RestaurantSearch,
        url: '/restaurant-search',
      },
      {
        component: RestaurantDetail,
        url: '/restaurant-detail/:id',
      },
      { component: RestaurantMenuEditor, url: '/restaurant-menu-editor/:id' },

      //Account Management VIEW/EDIT
      {
        component: AccountManagement,
        url: '/account-management',
      },
      {
        component: AccountSearch,
        url: '/account-search',
      },
      {
        component: AccountDetail,
        url: '/account-detail/:id',
      },
      {
        component: OrderDetail,
        url: '/restaurant-orders/:id',
      },

      //Delivery Management VIEW/EDIT
      {
        component: DeliveryMgmt,
        url: '/delivery-management',
      },
      {
        component: DeliveryDetail,
        url: '/delivery-detail',
      },
      {
        component: DeliveryDetailZone,
        url: '/delivery-detail-zone',
      },
      {
        component: DeliveryRestaurantDetail,
        url: '/delivery-detail-restaurant',
      },
      {
        component: DeliveryDetailLocation,
        url: '/delivery-detail-location',
      },
      { component: OfficeUserOrderDetail, url: '/office-user-order/:id' },
      { component: DeliveryDetailTime, url: '/delivery-detail-time' },
    ],
  },
  hungerhub_admin: {
    routes: [
      //Market Management VIEW/EDIT
      {
        component: MarketMgmt,
        url: '/market-management',
      },
      {
        component: RestaurantSchedules,
        url: '/restaurant-schedules',
      },
      {
        component: CateringOrders,
        url: '/catering-orders',
      },
      //Team Management VIEW/EDIT
      {
        component: StaffManagement,
        url: '/staff-management',
      },
    ],
  },
  account_management: {
    routes: [
      //Account Management VIEW/EDIT
      {
        component: AccountManagement,
        url: '/account-management',
      },
      {
        component: AccountSearch,
        url: '/account-search',
      },
      {
        component: AccountDetail,
        url: '/account-detail/:id',
      },
      {
        component: OrderDetail,
        url: '/restaurant-orders/:id',
      },
      {
        component: CateringOrders,
        url: '/catering-orders',
      },
      //Accounting Management Client VIEW/EDIT
      {
        component: AccountingMgmtClient,
        url: '/accounting-management-client',
      },

      //Meal Management VIEW/EDIT
      {
        component: AdminMealManagement,
        url: '/admin-meal-management',
      },

      //Restaurant Management VIEW
      {
        component: RestaurantManagement,
        url: '/restaurant-management',
      },
      {
        component: RestaurantSearch,
        url: '/restaurant-search',
      },
      {
        component: RestaurantDetail,
        url: '/restaurant-detail/:id',
      },
      { component: RestaurantMenuEditor, url: '/restaurant-menu-editor/:id' },

      //User Management VIEW/EDIT
      {
        component: UserManagement,
        url: '/user-management',
      },
      {
        component: UserSearch,
        url: '/user-search',
      },
      {
        component: UserDetail,
        url: '/user-detail/:id',
      },
      {
        component: EditBillingInfo,
        url: '/order-billing-info/:id',
      },

      //Place Order VIEW/EDIT
      {
        component: PlaceOrder,
        url: '/place-order',
      },
    ],
  },
  restaurant_management: {
    routes: [
      //Restaurant Management VIEW/EDIT
      {
        component: RestaurantManagement,
        url: '/restaurant-management',
      },
      {
        component: RestaurantSearch,
        url: '/restaurant-search',
      },
      {
        component: RestaurantDetail,
        url: '/restaurant-detail/:id',
      },
      { component: RestaurantMenuEditor, url: '/restaurant-menu-editor/:id' },

      {
        component: RestaurantSchedules,
        url: '/restaurant-schedules',
      },
      //Restaurant Tag Management VIEW/EDIT
      {
        component: RestaurantTagManagement,
        url: '/restaurant-tag-management',
      },

      //Accounting Management Restaurant VIEW/EDIT
      {
        component: AccountingMgmtRestaurant,
        url: '/accounting-management-restaurant',
      },

      //Meal Management VIEW
      {
        component: AdminMealManagement,
        url: '/admin-meal-management',
      },
    ],
  },
  customer_service: {
    routes: [
      //User Management VIEW/EDIT
      {
        component: UserManagement,
        url: '/user-management',
      },
      {
        component: UserSearch,
        url: '/user-search',
      },
      {
        component: UserDetail,
        url: '/user-detail/:id',
      },
      {
        component: EditBillingInfo,
        url: '/order-billing-info/:id',
      },

      //Restaurant Management VIEW
      {
        component: RestaurantManagement,
        url: '/restaurant-management',
      },
      {
        component: RestaurantSearch,
        url: '/restaurant-search',
      },
      {
        component: RestaurantDetail,
        url: '/restaurant-detail/:id',
      },
      { component: RestaurantMenuEditor, url: '/restaurant-menu-editor/:id' },

      //Account Management VIEW
      {
        component: AccountManagement,
        url: '/account-management',
      },
      {
        component: AccountSearch,
        url: '/account-search',
      },
      {
        component: AccountDetail,
        url: '/account-detail/:id',
      },
      {
        component: OrderDetail,
        url: '/restaurant-orders/:id',
      },

      //Place Order VIEW/EDIT
      {
        component: PlaceOrder,
        url: '/place-order',
      },

      //Meal Management VIEW/EDIT
      {
        component: AdminMealManagement,
        url: '/admin-meal-management',
      },
    ],
  },
  restaurant_support: {
    routes: [
      //Restaurant Management VIEW/EDIT
      {
        component: RestaurantManagement,
        url: '/restaurant-management',
      },
      {
        component: RestaurantSearch,
        url: '/restaurant-search',
      },
      {
        component: RestaurantDetail,
        url: '/restaurant-detail/:id',
      },
      { component: RestaurantMenuEditor, url: '/restaurant-menu-editor/:id' },
    ],
  },
  delivery_support: {
    routes: [
      //Delivery Management VIEW/EDIT
      {
        component: DeliveryMgmt,
        url: '/delivery-management',
      },
      {
        component: DeliveryDetail,
        url: '/delivery-detail',
      },
      {
        component: DeliveryDetailZone,
        url: '/delivery-detail-zone',
      },
      {
        component: DeliveryRestaurantDetail,
        url: '/delivery-detail-restaurant',
      },
      {
        component: DeliveryDetailLocation,
        url: '/delivery-detail-location',
      },
      { component: OfficeUserOrderDetail, url: '/office-user-order/:id' },
      { component: DeliveryDetailTime, url: '/delivery-detail-time' },
    ],
  },
  account_management_support: {
    routes: [
      //Account Management VIEW/EDIT
      {
        component: AccountManagement,
        url: '/account-management',
      },
      {
        component: AccountSearch,
        url: '/account-search',
      },
      {
        component: AccountDetail,
        url: '/account-detail/:id',
      },
      {
        component: OrderDetail,
        url: '/restaurant-orders/:id',
      },
    ],
  },
  meal_management_support: {
    routes: [
      //Meal Management VIEW/EDIT
      {
        component: AdminMealManagement,
        url: '/admin-meal-management',
      },
    ],
  },

  delivery_overview: {
    routes: [
      //Delivery Management VIEW
      {
        component: DeliveryMgmt,
        url: '/delivery-management',
      },
      {
        component: DeliveryDetail,
        url: '/delivery-detail',
      },
      {
        component: DeliveryDetailZone,
        url: '/delivery-detail-zone',
      },
      {
        component: DeliveryRestaurantDetail,
        url: '/delivery-detail-restaurant',
      },
      {
        component: DeliveryDetailLocation,
        url: '/delivery-detail-location',
      },
      { component: OfficeUserOrderDetail, url: '/office-user-order/:id' },
      { component: DeliveryDetailTime, url: '/delivery-detail-time' },
    ],
  },

  menu_editor: {
    routes: [
      //Restaurant Management VIEW but menu editor VIEW/EDIT
      {
        component: RestaurantManagement,
        url: '/restaurant-management',
      },
      {
        component: RestaurantSearch,
        url: '/restaurant-search',
      },
      {
        component: RestaurantDetail,
        url: '/restaurant-detail/:id',
      },
      { component: RestaurantMenuEditor, url: '/restaurant-menu-editor/:id' },
    ],
  },
  common: {
    routes: [],
  },
};
