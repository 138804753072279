import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Autocomplete from 'react-google-autocomplete';
import { faEdit, faCog } from '@fortawesome/free-solid-svg-icons';

function AddressInput({ provider, dispatch, label, mannualEntry = false, isDisabled = true }) {
  const [mannualAddress, setMannualAddress] = useState(mannualEntry);
  return (
    <div className="w-full">
      {!mannualAddress ? (
        <>
          <div className="d-row justify-between">
            <small className="input-label">{label}</small>

            <button
              disabled={isDisabled}
              onClick={() => {
                setMannualAddress(true);
              }}>
              <FontAwesomeIcon
                icon={faEdit}
                color={'#000'}
                size="1x"
                className="hover:text-gray-400"
              />
            </button>
          </div>
          <Autocomplete
            className="input-light"
            key={provider.autoCompleteAddress}
            defaultValue={provider.autoCompleteAddress}
            options={{
              componentRestrictions: { country: 'CA' },
              fields: ['address_components', 'geometry', 'formatted_address'],
              types: ['address'],
            }}
            apiKey={process.env.REACT_APP_GOOGLE_MAP_KEY}
            onPlaceSelected={(place, inputRef, autocomplete) => {
              const _address = place?.address_components?.reduce(
                (seed, { long_name, types }) => (types.forEach(t => (seed[t] = long_name)), seed),
                {}
              );
              setMannualAddress(true);

              dispatch({ field: 'autoCompleteAddress', value: place?.formatted_address });
              dispatch({ field: 'province', value: _address?.administrative_area_level_1 });
              dispatch({ field: 'streetName', value: _address?.route });
              dispatch({ field: 'city', value: _address?.locality });
              dispatch({ field: 'streetNumber', value: _address?.street_number });
              dispatch({ field: 'postalCode', value: _address?.postal_code });
            }}
          />
        </>
      ) : (
        <div>
          <div className="d-row justify-between">
            <small className="input-label">{label}</small>
            <button
              disabled={isDisabled}
              onClick={() => {
                setMannualAddress(false);
              }}>
              <FontAwesomeIcon
                icon={faCog}
                color={'#000'}
                size="md"
                className="hover:text-gray-400"
              />
            </button>
          </div>
          <div className="d-row flex-wrap">
            <div className="mx-2 md:mx-0.5">
              <input
                autoComplete="off"
                className="input-light"
                name="suite"
                id="suite"
                type="text"
                value={provider.suite}
                onChange={e => dispatch({ field: e.target.name, value: e.target.value })}
                placeholder="Suite Number"
                // required
              />
            </div>
            <div className="mx-2 md:mx-0.5">
              <input
                autoComplete="off"
                className="input-light"
                name="streetNumber"
                id="streetNumber"
                placeholder="Street Number"
                type="text"
                value={provider.streetNumber}
                onChange={e => dispatch({ field: e.target.name, value: e.target.value })}
                // required
              />
            </div>
            <div className="mx-2 md:mx-0.5">
              <input
                autoComplete="off"
                className="input-light"
                name="streetName"
                placeholder="Street Name"
                id="streetName"
                value={provider.streetName}
                onChange={e => dispatch({ field: e.target.name, value: e.target.value })}
                // required
              />
            </div>

            <div className="mx-2 md:mx-0.5">
              <input
                autoComplete="off"
                className="input-light"
                name="city"
                placeholder="City"
                id="city"
                value={provider.city}
                onChange={e => dispatch({ field: e.target.name, value: e.target.value })}
                // required
              />
            </div>
            <div className="mx-2 md:mx-0.5">
              <input
                autoComplete="off"
                className="input-light"
                name="province"
                id="province"
                placeholder="Province"
                value={provider.province}
                onChange={e => dispatch({ field: e.target.name, value: e.target.value })}
                // required
              />
            </div>
            <div className="mx-2 md:mx-0.5">
              <input
                autoComplete="off"
                className="input-light"
                name="postalCode"
                id="postalCode"
                placeholder="Postal Code"
                value={provider.postalCode}
                onChange={e => dispatch({ field: e.target.name, value: e.target.value })}
                // required
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
export default AddressInput;
