import array from './array';
import object from './object';

const getRoles = (employees, employeeType) => {
  return array.isEmpty(employees?.filter(emp => !emp?.attributes?.soft_deleted)) ? (
    `Not assigned to
  ${employeeType === 'Location' ? 'office' : 'restaurant'}`
  ) : (
    <div className="d-col">
      {employees
        ?.filter(emp => !emp?.attributes?.soft_deleted)
        ?.map(({ attributes: { role } }, index, arr) => {
          return (
            <small
              className={`input-label mt-1 ${index % 2 === 0 ? 'text-black' : 'text-dark-purple'}`}>
              {role
                ? role.includes('hungerhub')
                  ? role?.replace(/_/g, ' ').toLowerCase()
                  : role?.replace(/_/g, ' ').charAt(0).toUpperCase() +
                    role?.replace(/_/g, ' ').slice(1) +
                    (index !== arr?.length - 1 ? '\b' : '')
                : 'Not Decided'}
            </small>
          );
        })}
    </div>
  );
};

const getAccounts = (employees, employeeType) => {
  return array.isEmpty(employees?.filter(emp => !emp?.attributes?.soft_deleted)) ? (
    `Not assigned to
  ${employeeType === 'Location' ? 'office' : 'restaurant'}`
  ) : (
    <div className="d-col">
      {employees
        ?.filter(emp => !emp?.attributes?.soft_deleted)
        ?.map(({ attributes: { account_name } }, index, arr) => {
          return (
            <small
              className={`input-label mt-1 ${index % 2 === 0 ? 'text-black' : 'text-dark-purple'}`}>
              {account_name || '-'}
            </small>
          );
        })}
    </div>
  );
};

const getAccountString = (employees, employeeType) => {
  return array.isEmpty(employees?.filter(emp => !emp?.attributes?.soft_deleted))
    ? `Not assigned to 
  ${employeeType === 'Location' ? 'office' : 'restaurant'}`
    : employees
        ?.filter(emp => !emp?.attributes?.soft_deleted)
        ?.map(({ attributes: { account_name } }, index, arr) => {
          return (account_name || '-') + (index !== arr?.length - 1 ? ' | ' : '');
        })
        ?.toString()
        ?.replace(/,/g, ' ');
};

const getCreateOfficeUserRole = (provider, payload, defaultTeam = null) => {
  let team = provider?.team ? provider.team?.value : defaultTeam?.value;
  if (provider.team || defaultTeam) {
    if (provider?.teamAdmin && provider?.role?.value !== 'admin') {
      payload.location_employee = {
        ...payload?.location_employee,
        team_admins_attributes: [{ team_id: team }],
        team_id: team,
      };
    } else {
      payload.location_employee = {
        ...payload?.location_employee,
        team_id: team,
      };
    }
  }
  return payload;
};

const getUpdateOfficeUserRole = (provider, payload, assignedTeams) => {
  if (provider.team) {
    if (provider?.teamAdmin && provider?.role?.value !== 'admin') {
      let notAssigned = object.isEmpty(
        assignedTeams?.find(team => team.id === provider?.team?.value) || []
      );

      if (notAssigned) {
        payload.location_employee = {
          ...payload.location_employee,
          team_admins_attributes: [{ team_id: provider.team?.value }],
          team_id: provider.team?.value,
        };
      }
    } else {
      payload.location_employee = {
        ...payload.location_employee,
        team_id: provider?.team.value,
        // team_admins_attributes: assignedTeams?.map(team => {
        //   return { id: team.id, _destroy: true };
        // }),
      };
    }
  }
  return payload;
};

const getOfficeUserSubsidy = (provider, payload) => {
  if (provider.subsidyType === 'daily') {
    payload.location_employee = {
      ...payload.location_employee,
      daily_subsidy: Object.fromEntries(
        provider.dailySubsidies?.map(subsidy => {
          return [subsidy.name, parseFloat(subsidy.value).toFixed(2)];
        })
      ),
    };
  } else
    payload.location_employee = {
      ...payload.location_employee,
      subsidy: parseFloat(provider.weeklySubsidy).toFixed(2),
    };
  return payload;
};

export default {
  getRoles,
  getAccounts,
  getCreateOfficeUserRole,
  getUpdateOfficeUserRole,
  getOfficeUserSubsidy,
  getAccountString,
};
