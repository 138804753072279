import array from 'utils/helpers/array';
import LocalStorage from 'utils/helpers/localStorage';

export const alertHandler = (response, action = '') => {
  if (response?.data?.errors && response?.data?.errors[0] === 'User not authorized') {
    localStorage.clear();
  }

  let message = '';

  if (response?.status === 401) logout();
  else if (response?.status === 500)
    message = {
      title: 'Sorry!',
      body: 'We ran into an error, please refresh the page and try your request again. If you need any assistance please start a live chat or email us at help@hungerhub.com',
    };
  else
    message = response?.data
      ? {
          title: '',
          body: response?.data?.message
            ? response?.data.message
            : parseErrors(
                response?.data && response?.data?.errors
                  ? Array.isArray(response.data.errors)
                    ? `${response.data.errors.join(',')}`
                    : response.data.errors
                  : response.data || response.data.statusText
              ).join(','),
        }
      : response;

  if (response?.status !== 401) window.store.userStore.setErrorAlert(true, message, action);
};

export const logout = () => {
  window.store.userStore.clearStoredData();
  LocalStorage.removeKey('auth_token');
};

export const parseErrors = list => {
  if (typeof list === 'string') {
    list = [list];
  } else {
    if (list && list.length > 0 && typeof list[0] === 'string') {
      list = list;
    } else {
      list = Object.entries(list)?.map(([key, value]) => {
        return (
          array.isPresent(value) &&
          value?.map(err => {
            return key === 'base'
              ? value
              : `${key
                  .replace(/\./g, ' ')
                  .replace(/_/g, ' ')
                  .replace(/(?: |\b)(\w)/g, key => {
                    return key.toUpperCase();
                  })} ${err}`;
          })
        );
      });
    }
  }
  return list;
};
